<template>
  <div>
    <div class="search-bar">
      <a-form-model
        :labelCol="{ span: 8 }"
        :wrapperCol="{ span: 16 }"
        ref="searchRef"
        :model="searchForm"
      >
        <!-- 第一排 -->
        <a-row>
          <a-col :span="8">
            <a-form-model-item prop="outlets_city_adcode" label="平台 & 城市" 
              class="flex items-center">
              <div class="flex">
                <a-select class="flex-1 mr-2" placeholder="平台类型" 
                    v-model="searchForm.order_source">
                  <a-select-option v-for="(item, index) in sourceList"
                     :key="index" :value="item.id">{{ item.name }}</a-select-option>
                </a-select>
                <a-select class="flex-1" placeholder="选择订单城市"
                    v-model="searchForm.outlets_city_adcode">
                  <a-select-option v-if="!isCityManage" key="" value="">不限</a-select-option>
                  <a-select-option v-for="(item, index) in manageCityList"
                     :key="index" :value="Number(item.adcode)">{{ item.name }}</a-select-option>
                </a-select>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="other_order_no" label="第三方订单ID">
              <a-input v-model="searchForm.other_order_no" allowClear  placeholder="请输入订单ID"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="other_consignee_phone" label="第三方手机号">
              <a-input v-model="searchForm.other_consignee_phone" allowClear  placeholder="请输入第三方手机号"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 第二排 -->
        <a-row>
          <a-col :span="8">
            <a-form-model-item prop="other_order_add_time_arr" label="第三方下单日期">
              <a-range-picker v-model="searchForm.other_order_add_time_arr" valueFormat="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="other_order_finish_time_arr" label="订单全部完成日期">
              <a-range-picker v-model="searchForm.other_order_finish_time_arr" valueFormat="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="other_order_dispatch_staff_id"
                               label="调度人">
              <a-select show-search allowClear
                        placeholder="请输入调度人"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        @search="getStaffList"
                        v-model="searchForm.other_order_dispatch_staff_id">
                <a-select-option v-for="(item, index) in staffList"
                                 :key="index"
                                 :value="item.staff_id">{{item.staff_name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <!-- 第3排 -->
        <a-row>
          <a-col :span="8">
            <a-form-model-item prop="other_order_remarks" label="第三方备注">
              <a-input
                v-model="searchForm.other_order_remarks"
                allowClear
                placeholder="请输入备注"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="other_order_customer_service_remarks" label="第三方客服备注">
              <a-input
                v-model="searchForm.other_order_customer_service_remarks"
                enter-button
                allowClear
                placeholder="请输入备注"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <div class="flex items-center justify-end mr-1 mt-1">
              <a-button class="ml-4" @click="handlerResetForm">重置</a-button>
              <a-button class="ml-2" type="primary" html-type="submit" @click="handlerSearch">搜索</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form-model>
    </div>

    <div class="mt-3 flex justify-between items-center">
      <div class="flex items-center">
        <a-tabs type="card" :activeKey="activeKey"
                @change="handlerTypeChange">
          <a-tab-pane v-for="item in typeList"
                      :key="item.type_id"
                      :tab="item.type_name"></a-tab-pane>
        </a-tabs>
      </div>
      <a-button type="primary" html-type="submit"
            @click="reloadPage">刷新页面</a-button>
    </div>

    <!-- 全部订单列表 -->
    <base-table
      v-if="!activeKey"
      ref="orderThirdPartyTableRef"
      id="orderThirdPartyTableID"
      rowKey="key"
      :columnsData="columnsCur"
      :tableData="tableData"
      :total="total"
      :getListFunc="initData"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #order_status="{ record }">
        <a-tag class="mr-0" :color="getUserStatusTagClass(record.order_status)"
            >{{ formatUserOrderStatus(record.order_status, record.order_type) }}</a-tag>
      </template>
      <template #inner_status="{ record} ">
        <a-tag class="mr-0" :color="getInnerStatusTagClass(record.order_status)"
            >{{ formatInnerOrderStatus(record.order_status, record.order_type) }}</a-tag>
      </template>
    </base-table>

    <!-- 其他订单列表 -->
    <base-table
      v-else
      ref="orderThirdPartyTableRef"
      id="orderThirdPartyTableID"
      rowKey="key"
      :columnsData="columnsCur"
      :tableData="tableData"
      :total="total"
      :getListFunc="initDataOther"
      :page.sync="searchFormOther.page"
      :pageSize.sync="searchFormOther.page_count"
    >
      <template #toolbar v-if="activeKey==2">
        <!-- <div v-if="activeKey==1" class="ml-2">
          <a-button v-for="subItem in statusTypeListSub1" :key="subItem.id" 
              type="link" class="p-0 mr-6" :class="activeKeySub1==subItem.id?'':'grey' "
              @click="handlerSubTypeChange1(subItem.id)"
          >{{subItem.name}}</a-button>
        </div> -->
        <div v-if="activeKey==2" class="ml-2">
          <a-button v-for="subItem in statusTypeListSub2" :key="subItem.id" 
              type="link" class="p-0 mr-6" :class="activeKeySub2==subItem.id?'':'grey' "
              @click="handlerSubTypeChange2(subItem.id)"
          >{{subItem.name}}</a-button>
        </div>
      </template>
      <template #order_status="{ record }">
        <a-tag class="mr-0" :color="getUserStatusTagClass(record.order_status)"
            >{{ formatUserOrderStatus(record.order_status, record.order_type) }}</a-tag>
      </template>
      <template #inner_status="{ record} ">
        <a-tag class="mr-0" :color="getInnerStatusTagClass(record.order_status)"
            >{{ formatInnerOrderStatus(record.order_status, record.order_type) }}</a-tag>
      </template>
    </base-table>
  </div>
</template>




<script>
import { 
  formatUserOrderStatus,
  formatInnerOrderStatus,
  formatOtherOrderStatus,
  formatOtherOrderSource,
  orderSourceList
} from '@/utils/type'
import { 
  getMarketCityList,
  isCityManage,
} from '@/utils/authData.js'
import {
  getStaffList,
  otherOrderGetAllList,
  otherOrderGetOtherList,
} from "@/api/customer"
import pageData from "./columns"
import { rowSpan } from "@/utils/index"

export default {
  components: { },
  data() {
    return {
      ...pageData,
      columnsAll:[
        {
          title: "订单来源",
          dataIndex: "other_order_source",
          align: "center",
          width: 80,
          customRender: (text, row) => {
            return {
              children: ( formatOtherOrderSource(text) ),
              attrs: {
                rowSpan: row.other_order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "第三方订单ID",
          dataIndex: "other_order_no",
          align: "center",
          width: 190,
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.other_order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "第三方订单状态",
          dataIndex: "other_order_status",
          align: "center",
          width: 120,
          customRender: (text, row) => {
            return {
              children: ( formatOtherOrderStatus(text) ),
              attrs: {
                rowSpan: row.other_order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "第三方下单日期",
          dataIndex: "other_order_add_time",
          align: "center",
          width: 180,
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.other_order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "订单ID",
          dataIndex: "order_no",
          align: "center",
          width: 170,
        },
        // {
        //   title: "下单时间",
        //   dataIndex: "other_order_add_time",
        //   align: "center",
        //   width: 170,
        //   customRender: (text, row) => {
        //     return {
        //       children: text,
        //       attrs: {
        //         rowSpan: row.other_order_noRowSpan, //需要合并的数据
        //       },
        //     }
        //   },
        // },
        {
          title: "负责网点",
          width: "15%",
          dataIndex: "actual_sale_ascription_outlets",
          align: "center",
        },
        {
          title: "类别",
          dataIndex: "order_type",
          align: "center",
          width: 90,
          slots: {
            customRender: "order_type",
          },
          slotsType: "format",
          slotsFunc: (val) => {
            if(val == 1) return "自提"
            if(val == 2) return "配送"
            if(val == 3) return "快递"
            if(val == 4) return "配送+快递"
            return ""
          },
        },
        {
          title: "订单状态",
          dataIndex: "order_status",
          align: "center",
          width: 90,
          slots: {
            customRender: "order_status",
          },
        },
        {
          title: "内部状态",
          width: 90,
          dataIndex: "inner_status",
          align: "center",
          slots: { customRender: 'inner_status' },
        },
        {
          title: "调度人",
          dataIndex: "other_order_dispatch_staff",
          width: "12%",
          align: "center",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.other_order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          width: 80,
          customRender: (text, row) => {
            return {
              children: (
                <div>
                  <a-button  type="link"
                    onClick={() => {
                      this.handlerDetailBlank(row)
                    }}
                  >
                    详情
                  </a-button>
                </div>
              ),
              attrs: {
                rowSpan: row.other_order_noRowSpan, //需要合并的数据
              },
            }
          }
        },
      ],
      columnsCur: [],

      isCityManage: isCityManage(),
      manageCityList: [],
      sourceList: [{
          id: 0,
          name: "不限平台",
        },{
          id: -1,
          name: "全部电商平台",
        },{
          id: -2,
          name: "全部外卖平台",
          isTakeaway: true,
        }].concat(orderSourceList.filter(el=>el.id>=20)),

      searchForm: {
        order_source: 0,
        outlets_city_adcode: undefined,
        other_order_no: "",
        other_consignee_phone: "",
        other_order_add_time_arr: [],
        other_order_add_time_start: "",
        other_order_add_time_end: "",
        other_order_finish_time_arr:[],
        other_order_finish_time_start:"",
        other_order_finish_time_end:"",
        other_order_remarks:"",
        other_order_customer_service_remarks:"",
        other_order_dispatch_staff_id: undefined,

        page: 1,
        page_count: 20,
      },

      searchFormOther: {
        order_source: 0,
        outlets_city_adcode: undefined,
        tab_type: '',
        tab_subtype: '',
        page: 1,
        page_count: 20,
      },

      activeKey: "",
      typeList: [
        { type_id: 1,  type_name: "待建单" },
        { type_id: 2, type_name: "已建单" },
        { type_id: 3, type_name: "第三方修改" },
        { type_id: 4,  type_name: "不处理订单" },
        { type_id: "", type_name: "全部订单" },
        { type_id: 5, type_name: "待对账订单" }
      ],
      
      activeKeySub1: 1,
      statusTypeListSub1: [
        { id: 1,  name: "可识别商品" },
        { id: 2, name: "含不可识别商品" },
      ],   
      
      activeKeySub2: 1,
      statusTypeListSub2: [
        { id: 1,  name: "未完成订单" },
        { id: 2, name: "已取消订单" },
        { id: 3,  name: "已完成订单" },
        { id: 4, name: "售后订单" },
      ], 
      staffList: [],

      tableData: [],
      total: 0,

    }
  },
  activated() {
  },
  created() {
  },
  async mounted() {
    // 类型：城市只能看外卖
    if(this.isCityManage){
      this.searchForm.order_source = -2
      this.sourceList = this.sourceList.filter(el=>el.isTakeaway)
    }

    // 城市
    this.manageCityList = await getMarketCityList()
    if(this.isCityManage){
      // 如果只管理一个城市：默认选中
      if(this.manageCityList.length == 1){
        this.searchForm.outlets_city_adcode = Number(this.manageCityList[0].adcode)
      }
    }
    if(this.isCityManage){
      if(this.searchForm.outlets_city_adcode){
        this.handlerTypeChange()
      }
    }else{
      this.handlerTypeChange()
    }

    // this.handlerTypeChange()
  },
  watch: {
  },

  methods: {
    formatUserOrderStatus,
    formatInnerOrderStatus,
    reloadPage() {
      this.handlerResetForm(this.activeKey)
    },
    handlerResetForm() {
      this.searchForm.page = 1
      this.searchForm.page_count = 20
      this.$refs.searchRef.resetFields()
      this.searchForm.other_order_add_time_start = ""
      this.searchForm.other_order_add_time_end = ""
      this.searchForm.other_order_finish_time_start = ""
      this.searchForm.other_order_finish_time_end = ""
      // this.initData()
      this.handlerTypeChange()
    },
    handlerSearch() {
      this.searchForm.page = 1
      // this.initData()
      this.handlerTypeChange()
    },
    async initData() {

      if (this.searchForm.other_order_add_time_arr.length > 0) {
        this.searchForm.other_order_add_time_start = this.searchForm.other_order_add_time_arr[0]
        this.searchForm.other_order_add_time_end = this.searchForm.other_order_add_time_arr[1]
      }else{
        this.searchForm.other_order_add_time_start = ""
        this.searchForm.other_order_add_time_end = ""
      }
      if (this.searchForm.other_order_finish_time_arr.length > 0) {
        this.searchForm.other_order_finish_time_start = this.searchForm.other_order_finish_time_arr[0]
        this.searchForm.other_order_finish_time_end = this.searchForm.other_order_finish_time_arr[1]
      }else{
        this.searchForm.other_order_finish_time_start = ""
        this.searchForm.other_order_finish_time_end = ""
      }

      let { data, code } = await otherOrderGetAllList(this.searchForm)


      if (code === 0) {
        // this.tableData = data.list
        this.total = data.total_count
        let baseArr = []
        data.list.map((item, i) => {
          if(item.order_list.length == 0){
            item.order_list.push({})
          }
          item.order_list.map((el, j) => {
            let obj = item
            obj.key = i + "-" + j
            obj = { ...obj, ...el }
            delete obj.order_list
            baseArr.push(obj)
          })
        })
        let tableArr = rowSpan(baseArr, "other_order_no")
        this.tableData = tableArr

        this.$refs.orderThirdPartyTableRef.tableHeight = 
        this.$refs.orderThirdPartyTableRef.getTableScroll()
      }
    },
    async initDataOther() {
      let params = this.searchFormOther

      let { data, code } = await otherOrderGetOtherList(params)

      if (code === 0) {
        this.total = data.total_count
        this.tableData = []
        if(data.list.length == 0){
          return
        }
        let baseArr = []
        data.list.map((item, i) => {
          if(!item.order_list) {
            item.order_list = [{}]
          }
          item.order_list.map((el, j) => {
            let obj = item
            obj.key = i + "-" + j
            obj = { ...obj, ...el }
            delete obj.order_list
            baseArr.push(obj)
          })
        })
        let tableArr = rowSpan(baseArr, "other_order_no")
        this.tableData = tableArr

        this.$refs.orderThirdPartyTableRef.tableHeight = 
        this.$refs.orderThirdPartyTableRef.getTableScroll()
      }
      // console.log('listother', this.tableData)
    },
    handlerTypeChange(type_id) {
      this.activeKey = type_id || ""
      this.searchFormOther.order_source = this.searchForm.order_source
      this.searchFormOther.outlets_city_adcode = this.searchForm.outlets_city_adcode
      this.searchFormOther.tab_type = type_id || ""
      this.searchFormOther.tab_subtype = ""
      this.searchFormOther.page = 1
      this.searchForm.page = 1

      if(type_id==1 || type_id==4 || type_id==5) {
        this.columnsCur = this.columnsAll.filter(
          (item) =>!["other_order_status","order_no","actual_sale_ascription_outlets","order_type","order_status","inner_status","other_order_dispatch_staff"].includes(item.dataIndex)
        )
      }else if(!type_id){
        // this.columnsCur = this.columnsAll.filter(
        //   (item) =>!["other_order_source"].includes(item.dataIndex)
        // )
        this.columnsCur = this.columnsAll
      }else{
        // this.columnsCur = this.columnsAll.filter(
        //   (item) =>!["other_order_source","other_order_add_time"].includes(item.dataIndex)
        // )
        this.columnsCur = this.columnsAll.filter(
          (item) =>!["other_order_add_time"].includes(item.dataIndex)
        )
      }

      if(!type_id) {
        this.initData()
        return
      }
      else if(type_id == 1) {
        // this.handlerSubTypeChange1(1)
        this.initDataOther()
        return
      }else if(type_id == 2) {
        this.handlerSubTypeChange2(1)
        return
      }else {
        this.initDataOther()
      }
    },
    handlerSubTypeChange1(subId) {
      this.activeKeySub1 = subId||""
      this.searchFormOther.page = 1
      this.searchFormOther.tab_type = 1
      this.searchFormOther.tab_subtype = subId
      
      this.initDataOther()
    },
    handlerSubTypeChange2(subId) {
      this.activeKeySub2 = subId||""
      this.searchFormOther.page = 1
      this.searchFormOther.tab_type = 2
      this.searchFormOther.tab_subtype = subId
      
      this.initDataOther()
    },

    async getStaffList (value) {
      if (value) {
        const { data, code } = await getStaffList({
          staff_name: value,
        })
        if (code === 0) this.staffList = data.list
      }
    },

    handlerDetailBlank(row) {
      // let query = {
      //   other_order_id: row.other_order_id
      // }
      // if(this.activeKey == 4) {//不处理订单
      //   query.ignore = 1
      // }
      const newPage = this.$router.resolve({
        name: "otherPlatOrderDetail",
        query: {
          other_order_id: row.other_order_id
        },
      });
      window.open(newPage.href, "_blank");
    },

    getUserStatusTagClass(status){
      // if(status < 0) return "red"
      if(status < 0) return "grey"
      // if(status < 3) return ""
      if(status < 30) return "blue"
      if(status == 30) return "green"
      if(status >= 40) return "red"
      // if(status >= 40) return "grey"
      return ""
    },

    getInnerStatusTagClass(status){
      // if(status < 0) return "red"
      if(status < 0) return "grey"
      if(status < 3) return ""
      if(status < 30) return "blue"
      if(status >= 30) return "green"
      // if(status >= 40) return "red"
      // if(status >= 40) return "grey"
      return ""
    },
  },
}
</script>



<style lang="less" scoped>


</style>
<style lang="less">
.grey{
  color: #999;
}
</style>